import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getDefaultAdAlt } from '../../../../utils/ads/adsUtils';

const NativeAd = ({ adParams, adSlot, adPlacement }) => {
  const adId = `ad-${adSlot}`;
  // these lines will be duplicated on the RegularAd component because the components
  // will be replaced by the one in the ui-library
  const trackingPixel = adPlacement?.tracking_pixel;
  const elegibleUrl = adPlacement?.eligible_url;
  const viewableUrl = adPlacement?.viewable_url;
  const adRef = useRef(null);
  const viewableImgRef = useRef(null);
  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && viewableImgRef.current && viewableUrl) {
          viewableImgRef.current.src = viewableUrl;
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    adRef.current && observer.observe(adRef.current);

    return () => {
      observer.disconnect();
    };
  }, [viewableUrl]);
  const nativeClass = `${adParams[adSlot].classes} native-ad`;
  return (
    <div ref={adRef} id={adId} className={nativeClass} data-zone-id={adParams[adSlot]?.zoneID}>
      <a key={Math.random()} className="adslot-target" href={adPlacement.redirect_url} rel="sponsored noreferrer"
         target="_blank" dangerouslySetInnerHTML={{ __html: adPlacement.body }} />
      { trackingPixel && <img alt={getDefaultAdAlt(`${adId}-pixel`)} className="tracking-pixel" src={trackingPixel} /> }
      { elegibleUrl && <img alt={getDefaultAdAlt(`${adId}-elegible`)} className="tracking-pixel" src={elegibleUrl} /> }
      { viewableUrl && <img ref={viewableImgRef} alt={getDefaultAdAlt(`${adId}-viewable`)} className="tracking-pixel" /> }
    </div>
  );
};

NativeAd.propTypes = {
  adParams: PropTypes.object,
  adSlot: PropTypes.string,
  adPlacement: PropTypes.object
};

export default NativeAd;
